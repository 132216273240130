* {
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.button:hover span:first-child {
  transform: translateX(0); /* Move the background into view on hover */
}

.container {
  display: flex;
  width: 80%;
  max-width: 1200px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 8px;
  margin: 20px auto;
}

.text-section {
  flex: 1;
  padding: 20px;
}

.text-section h1 {
  font-size: 28px;
  color: #293a4b;
  margin: 0;
}

.text-section p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.image-section img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
